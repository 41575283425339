import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    SimpleChange,
    ViewChild,
} from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { ConnectionApiService } from 'src/utility/services/connection-api.service';
import { SweetAlert } from 'src/utility/sweetAlert';
import { ModalComponent } from '../modal/modal.component';
import { Contract } from 'src/utility/Storage/contract';
import { EncryptedCookieStorageService } from 'src/utility/services/marketplace/encrypted-cookie-storage.service';
declare var $: any;

@Component({
    selector: 'app-product-catalog',
    templateUrl: './product-catalog.component.html',
    styleUrls: ['./product-catalog.component.scss'],
    host: {
        '(window:resize)': 'getWindowWidth()',
    },
})
export class ProductCatalogComponent implements OnInit, AfterViewInit {
    @ViewChild('modalLoaderxs') modalLoaderxs: ModalComponent;
    @Input() categorySelected: any = null;
    @Input()
    countrySelect: { value: any; currency: any };
    lastWidth: number = 0;
    numberElementsDisplay: number = 0;
    productCatalog: any[] = [];
    @Output() componentLoaded: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private api: ConnectionApiService,
        private sweet: SweetAlert,
        private encryptedCookieStorageService: EncryptedCookieStorageService
    ) {}

    async ngOnInit() {
        await this.getAllProducts();
    }

    ngAfterViewInit(): void {
        this.componentLoaded.emit();
    }

    ngOnChanges(changes: any) {
        if (changes?.categorySelected != undefined) {
            if (this.categorySelected != '') {
                if (this.categorySelected) {
                    this.getProductsForCategory(this.categorySelected);
                } else {
                    this.getAllProducts();
                }
            }
        }
    }

    async getAllProducts() {
        this.productCatalog = [];
        const body = {
            path: 'MarketPlace/LoadingElementsandSubelements',
            data: {
                Method: 'LoadingElementsandSubelements',
                Params: {
                    CountryId: this.countrySelect.value,
                },
            },
        };
        this.modalLoaderxs?.Open();
        try {
            let response = await this.api.postData(body, false).toPromise();
            this.modalLoaderxs?.Close();
            this.productCatalog = response.ObjectValue;
            if (!response.ObjectValue || response.StatusCode != 200) {
                return;
            }
            for (let i = 0; i < this.productCatalog.length; i++) {
                this.productCatalog[i] = {
                    ...this.productCatalog[i],
                    ...{ viewMore: true },
                };
            }
            setTimeout(() => {
                this.getWindowWidth(true);
            }, 1000);
        } catch (error) {
            this.modalLoaderxs?.Close();
            console.error(error);
        }
    }

    async getProductsForCategory(item) {
        this.productCatalog = [];
        const body = {
            path: 'MarketPlace/GetProductsByCategory',
            data: {
                Method: 'GetProductsByCategory',
                Params: {
                    IdCatProductCategory: item.IdCatProductCategory,
                },
            },
        };
        this.modalLoaderxs?.Open();
        try {
            let response = await this.api.postData(body, false).toPromise();
            this.modalLoaderxs?.Close();
            if (!response.ObjectValue || response.StatusCode != 200) {
                return;
            }
            if (response.ObjectValue.length == 0) {
                return;
            }
            let Products = [];
            for (let i = 0; i < response.ObjectValue.length; i++) {
                Products[i] = {
                    ...Products[i],
                    ...{ Image: response.ObjectValue[i].CatProductImageList },
                    ...{
                        CatProductCost: response.ObjectValue[i].CatProductCost,
                    },
                    ...{
                        CatProductDescription:
                            response.ObjectValue[i].CatProductDescription,
                    },
                    ...{ Name: response.ObjectValue[i].CatProductName },
                    ...{ IdCatProduct: response.ObjectValue[i].IdCatProduct },
                    ...{ Tag: response.ObjectValue[i].Tag },
                    ...{
                        CurrencySymbol: response.ObjectValue[i].CurrencySymbol,
                    },
                };
            }

            this.productCatalog[0] = {
                ...this.productCatalog[0],
                ...{ Products: Products },
            };
            for (let i = 0; i < this.productCatalog.length; i++) {
                this.productCatalog[i] = {
                    ...this.productCatalog[i],
                    ...{ viewMore: true },
                };
            }
            setTimeout(() => {
                this.getWindowWidth(true);
            }, 1000);
        } catch (error) {
            this.modalLoaderxs?.Close();
            console.error(error);
        }
    }

    async viewMore(index) {
        this.productCatalog[index].viewMore =
            !this.productCatalog[index].viewMore;

        let element = document.getElementById(
            `principal-container-products-carousel-${index}`
        );
        let subElement = document.getElementById(
            `container-products-carousel-${index}`
        );

        if (this.productCatalog[index].viewMore) {
            element.setAttribute(
                'style',
                `max-height: ${subElement.clientHeight - 1}px !important`
            );
        } else {
            if (
                this.numberElementsDisplay <
                this.productCatalog[index].Products.length
            ) {
                element.setAttribute('style', `max-height: 100% !important`);
            }
        }
    }

    getWindowWidth(forcedReset: boolean = false) {
        if (this.productCatalog.length == 0) {
            return;
        }
        let width = document.body?.clientWidth;
        if (width == this.lastWidth && !forcedReset) {
            return;
        }
        this.lastWidth = width;
        if (width >= 1200) {
            this.numberElementsDisplay = 4;
        } else if (width >= 992 && width < 1200) {
            this.numberElementsDisplay = 3;
        } else if (width >= 576 && width < 992) {
            this.numberElementsDisplay = 2;
        } else {
            this.numberElementsDisplay = 1;
        }
        this.resetValues();
    }

    async resetValues() {
        let containerCarouselName = document.getElementsByName(
            'principal-container-products-carousel-name'
        );
        containerCarouselName.forEach((element) => {
            element.removeAttribute('style');
        });

        for (let i = 0; i < this.productCatalog.length; i++) {
            let element = document.getElementById(
                `principal-container-products-carousel-${i}`
            );
            let subElement = document.getElementById(
                `container-products-carousel-${i}`
            );
            element.setAttribute(
                'style',
                `max-height: ${subElement.clientHeight - 1}px !important`
            );
            this.productCatalog[i].viewMore = true;
        }
    }
}
