import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { SecureSimpleStorage } from 'src/utility/Storage/secure-simple-storage';
import { ConnectionApiService } from 'src/utility/services/connection-api.service';
import { SweetAlert } from 'src/utility/sweetAlert';

@Component({
    selector: 'app-image-carousel',
    templateUrl: './image-carousel.component.html',
    styleUrls: ['./image-carousel.component.scss'],
    host: {
        '(window:resize)': 'getWindowWidth()',
    },
})
export class ImageCarouselComponent implements OnInit, AfterViewInit {
    lastWidth: number = 0;
    @Input()
    countrySelect: { value: any };
    slideConfig = {
        slidesToShow: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 10000,
        dots: false,
        prevArrow: '',
        nextArrow: '',
    };
    data: any = [];
    width: string = '';
    @ViewChild('slickModal') next?: SlickCarouselComponent;
    @ViewChild('slickModal') slickModal: SlickCarouselComponent;
    @Output() componentLoaded: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private secureStorage: SecureSimpleStorage,
        private api: ConnectionApiService,
        private sweet: SweetAlert
    ) {}

    ngOnInit(): void {
        this.getWindowWidth(true);
        this.getImages();
    }

    ngAfterViewInit(): void {
        this.componentLoaded.emit();
    }

    async getImages() {
        const body = {
            path: 'MarketPlace/GetImagePromotional',
            data: {
                Method: 'GetImagePromotional',
                Params: {
                    CountryId: this.countrySelect.value,
                },
            },
        };
        try {
            let response = await this.api.postData(body).toPromise();
            if (response.StatusCode != 200 || !response.ObjectValue) {
                return;
            }
            this.data = response.ObjectValue;
        } catch (error) {
            console.error(error);
        }
    }

    getWindowWidth(ignoreRestriction: boolean = false) {
        let width = document.body?.clientWidth;
        if (width == this.lastWidth && !ignoreRestriction) {
            return;
        }
        this.lastWidth = width;
        setTimeout(() => {
            this.slickModal?.unslick();
            this.slickModal?.initSlick();
            setTimeout(() => {
                let width = document.body.clientWidth;
                if (width > 1920) {
                    this.width = 'XXXLImageURL';
                } else if (width >= 1200 && width <= 1920) {
                    this.width = 'XXLImageURL';
                } else if (width >= 992 && width < 1200) {
                    this.width = 'XLImageURL';
                } else if (width >= 768 && width < 992) {
                    this.width = 'MDImageURL';
                } else {
                    this.width = 'XSImageURL';
                }
            }, 400);
        }, 100);
    }

    goToLink(item) {
        if (!item?.HyperlinkURL || item?.HyperlinkURL == '') {
            return;
        }
        const ventana = window.open(item?.HyperlinkURL, '_blank');
    }
}
