import { LinkEntregaService } from './../../utility/services/oac/link-entrega.service';
import {
    AfterViewInit,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { SecureSimpleStorage } from 'src/utility/Storage/secure-simple-storage';
import { ConnectionApiService } from 'src/utility/services/connection-api.service';
import { SweetAlert } from 'src/utility/sweetAlert';
import { Subscription } from 'rxjs';
import { CommunicationWithObservableService } from 'src/utility/services/communication-with-observable-service.service';
import { JoyrideService } from 'ngx-joyride';
import { allStepsArray } from 'src/utility/functions/OnBoardingCrearGuia.service';
import { EncryptedCookieStorageService } from 'src/utility/services/marketplace/encrypted-cookie-storage.service';
import { Contract } from 'src/utility/Storage/contract';

@Component({
    selector: 'app-category-carousel',
    templateUrl: './category-carousel.component.html',
    styleUrls: ['./category-carousel.component.scss'],
    host: {
        '(window:resize)': 'getWindowWidth()',
    },
})
export class CategoryCarouselComponent implements OnInit, AfterViewInit {
    lastWidth: number = 0;
    componentForTutorial$: Subscription;
    @Input()
    countrySelect: { value: any };
    @Output()
    sendCategorySelected: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('slickModal') slickModal: SlickCarouselComponent;
    slideConfig = {
        slidesToShow: 4,
        infinite: true,
        dots: false,
        prevArrow: '',
        nextArrow: '',
    };
    categorySelected: any = null;
    categories: any = [];
    fontSize: string = '';
    @ViewChild('slickModal') next?: SlickCarouselComponent;
    @Output() componentLoaded: EventEmitter<void> = new EventEmitter<void>();
    AllArraySteps = allStepsArray;
    constructor(
        private secureStorage: SecureSimpleStorage,
        private api: ConnectionApiService,
        private sweet: SweetAlert,
        private communicationWithObservable: CommunicationWithObservableService,
        private readonly joyrideService: JoyrideService
    ) {}

    ngOnInit(): void {
        this.getCategories();
        this.componentForTutorial$ = this.communicationWithObservable
            .onComponentForTutorial()
            .subscribe(async (message) => {
                if (message.text == '1') {
                    this.startMyTourSourceAddress();
                    let element = document.getElementById(
                        'containerCategoriesCarousel'
                    );
                    setTimeout(() => {
                        element.scrollIntoView({ block: 'center' });
                    }, 100);
                }
            });
    }

    ngAfterViewInit(): void {
        this.componentLoaded.emit();
    }

    ngOnDestroy(): void {
        this.componentForTutorial$.unsubscribe();
    }

    async getCategories() {
        const body = {
            path: 'MarketPlace/LoadProductCategory',
            data: {
                Method: 'LoadProductCategory',
                Params: {
                    CountryId: this.countrySelect.value,
                },
            },
        };
        try {
            let response = await this.api.postData(body).toPromise();
            if (response.StatusCode != 200 || !response.ObjectValue) {
                return;
            }
            this.categories = response.ObjectValue;
            setTimeout(() => {
                this.getWindowWidth();
            }, 0);
        } catch (error) {
            console.error(error);
        }
    }

    selectCategory(item) {
        if (item == this.categorySelected) {
            this.categorySelected = null;
            this.sendCategorySelected.emit(null);
            this.validClonedElements();
            return;
        }
        this.categorySelected = item;
        this.sendCategorySelected.emit(this.categorySelected);
        this.validClonedElements();
    }

    getWindowWidth() {
        if (this.categories.length == 0) {
            return;
        }
        let width = document.body?.clientWidth;
        if (width == this.lastWidth) {
            return;
        }
        this.lastWidth = width;
        if (width >= 800) {
            this.slideConfig = {
                slidesToShow: 4,
                infinite: true,
                dots: false,
                prevArrow: '',
                nextArrow: '',
            };
        } else if (width >= 600 && width < 799) {
            this.slideConfig = {
                slidesToShow: 3,
                infinite: true,
                dots: false,
                prevArrow: '',
                nextArrow: '',
            };
        } else if (width >= 420 && width < 599) {
            this.slideConfig = {
                slidesToShow: 2,
                infinite: true,
                dots: false,
                prevArrow: '',
                nextArrow: '',
            };
        } else {
            this.slideConfig = {
                slidesToShow: 1,
                infinite: true,
                dots: false,
                prevArrow: '',
                nextArrow: '',
            };
        }
        setTimeout(() => {
            this.getFontSize();
            this.slickModal?.unslick();
            this.slickModal?.initSlick();
        }, 100);
    }

    getFontSize() {
        let cardCategory = document.getElementById('card-category');
        let font = (cardCategory.clientWidth * 200) / 200;
        document.documentElement.style.setProperty(
            '--font-size-percentage',
            `${font}%`
        );
    }

    startMyTourSourceAddress(): void {
        let steps = ['step1'];
        const options = {
            steps: steps,
            stepDefaultPosition: 'bottom',
            themeColor: '#345632',
            showPrevButton: true,
            showCounter: false,
            customTexts: {
                next: '>>',
                prev: '<<',
                done: '>>',
            },
        };
        var stepControll;
        this.joyrideService.startTour(options).subscribe(
            (step) => {
                stepControll = step;
            },
            (e) => {},
            () => {
                this.communicationWithObservable.sendComponentForTutorial('2');
            }
        );
    }

    init(changeEvent: any) {
        const elements =
            changeEvent.event.target.lastElementChild.firstChild.getElementsByClassName(
                'slick-cloned'
            ) as HTMLElement[];
        for (const element of elements) {
            if (!element.classList.contains('listen')) {
                element.classList.add('listen');
                element.addEventListener('click', (clickEvent: any) => {
                    for (let item of this.categories) {
                        let category = element.getElementsByClassName(
                            item.ProductCategoryName
                        );
                        if (category.length > 0) {
                            if (
                                item?.ProductCategoryName ==
                                this.categorySelected?.ProductCategoryName
                            ) {
                                category[0].classList.remove(
                                    'category-selected'
                                );
                            } else {
                                category[0].classList.add('category-selected');
                            }
                            this.selectCategory(item);
                        }
                    }
                });
            }
        }
    }

    validClonedElements() {
        let carousel = document.getElementById('carouselDetailCategories');
        const elements: any = carousel.getElementsByClassName('slick-cloned');
        for (const element of elements) {
            let categoryReset = element.getElementsByClassName(
                'class-identificator-categories'
            );
            if (categoryReset.length > 0) {
                categoryReset[0].classList.remove('category-selected');
            }
            let categorySelect = element.getElementsByClassName(
                this.categorySelected?.ProductCategoryName
            );
            if (categorySelect.length > 0) {
                categoryReset[0].classList.add('category-selected');
            }
        }
    }
}
